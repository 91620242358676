import { DestinyItemComponent, DestinyItemSubType, DestinySeasonDefinition } from 'bungie-api-ts/destiny2';
import { ComponentFactoryResolver, Type, ViewContainerRef } from '@angular/core';
import { Observable, window } from 'rxjs';
import { ModaleComponent } from './common/modale/modale.component';
import { DestinyRecipesItem } from './types/destiny-recipes-item';

export interface DestinySeasonWithPowerCapDefinition extends DestinySeasonDefinition {
  powerCap?: number;
}

export interface DestinyItemComponentCharacterized extends DestinyItemComponent {
  character?: string;
}

export enum LoadingComponent {
  NONE = 'NONE',
  AUTH = 'AUTH',
  MANIFEST = 'MANIFEST',
  INVENTORY = 'INVENTORY',
  VOLTRON = 'VOLTRON',
  COMMON = 'COMMON'
}

export class LoadingError extends Error {
  constructor(public component: LoadingComponent) {
    super();
  }
}

export interface ModalServiceBackbone {
  rootViewContainer: ViewContainerRef;
  factoryResolver: ComponentFactoryResolver;
  setRootViewContainerRef(viewContainerRef: ViewContainerRef): void;
  create<U = any>(modalConfig: ModalConfig<U>): Observable<ModalResponse<U>>;
  destroy(): void;
}

export interface ModalConfig<U> {
  title: string;
  async?: boolean;
  cancelButton?: boolean;
  modalBodyClass?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  component: Type<ModalContentDialog<U>>;
  data?: any;
}

export interface ModalResponse<U = any> {
  data?: U;
  canceled: boolean;
}

export interface ModalContentDialog<U = any> {
  readonly modalReference: ModaleComponent;
  data: any;
  asyncClose?(): any;
  getData(): U;
}

// API


export interface BungieApiResponse<T> {
  Response: T;
  ErrorCode: number;
  ThrottleSeconds: number;
  ErrorStatus: string;
  Message: string;
  MessageData: any;
}

export interface RecipeApiResponse<T = any> {
  status: 'success' | 'error';
  message?: string;
  values?: T[];
  value?: T;
  score?: number;
  exists?: boolean;
  pagination?: {
    offset: number;
    limit: number;
    total: number;
  };
}

export interface ScoreDocument {
  membershipId: number;
  serializedMembership?: string;
  score: number;
  uniqueName: string;
  lastUpdate: number;
}

export interface HallOfFameEntry {
  seasonNumber: number;
  leaderboard: ScoreDocument[];
}

export enum InventoryBucket {
  KINETIC_WEAPON = 1498876634,
  ENERGY_WEAPON = 2465295065,
  POWER_WEAPON = 953998645,
  HELMET = 3448274439,
  GAUNTLETS = 3551918588,
  CHEST = 14239492,
  LEGS = 20886954,
  CLASS_ITEM = 1585787867,
  SHELL = 4023194814,
  SPARROW = 2025709351,
  SHIP = 284967655,
  SUBCLASS = 3284755031,
  EMBLEM = 4274335291,
  FINISHER = 3683254069,
  EMOTES = 1107761855,
  ARTIFACT = 1506418338,
  BANNER = 4292445962,
}

export enum SocketHash {
  LastWish = 1444083081,
  GardenOfSalvation = 1764679361,
  DeepStoneCrypt = 1269555732,
  VaultOfGlass = 3372624220,
  VowOfTheDisciple = 2381877427,
  KingsFall = 3344538838,
  RootOfNightmare = 1956816524,
  CrotasEnd = 2804745000,
  Trackers = 1282012138,
}

export const DestinySymbolResolver = (value: any) => {
  switch (value) {
    case DestinyItemSubType.Bow:
    case '[Bow]':
    case '[弓]':
    case '[Łuk]':
    case '[Bogen]':
    case '[Лук]':
    case '[활]':
      return '\ue099'; // 1213975022

    case DestinyItemSubType.AutoRifle:
    case '[Auto Rifle]':
    case '[Fusil automatique]':
    case '[オートライフル]':
    case '[Karabin automatyczny]':
    case '[Automatikgewehr]':
    case '[Fusil automático]':
    case '[Fucile automatico]':
    case '[Автомат]':
    case '[자동 소총]':
    case '[Fuzil automático]':
      return '\ue100'; // 60057218

    case DestinyItemSubType.PulseRifle:
    case '[Pulse Rifle]':
    case '[Fusil à impulsion]':
    case '[Fucile a impulsi]':
    case '[Fuzil de Pulso]':
    case '[Karabin pulsacyjny]':
    case '[Impulsgewehr]':
    case '[Fusil de pulsos]':
    case '[パルスライフル]':
    case '[파동 소총]':
    case '[Импульсная винтовка]':
      return '\ue101'; // 1655762139

    case DestinyItemSubType.ScoutRifle:
    case '[Scout Rifle]':
    case '[Fusil d\'éclaireur]':
    case '[Fucile da ricognizione]':
    case '[Fuzil de batedor]':
    case '[Karabin zwiadowczy]':
    case '[Scout-Gewehr]':
    case '[Fusil de explorador]':
    case '[スカウトライフル]':
    case '[정찰 소총]':
    case '[Винтовка разведчика]':
      return '\ue102'; // 236969823

    case DestinyItemSubType.HandCannon:
    case '[Hand Cannon]':
    case '[Revolver]':
    case '[Cannone portatile]':
    case '[Canhão de mão]':
    case '[Rewolwer]':
    case '[Handfeuerwaffe]':
    case '[Cañón de mano]':
    case '[ハンドキャノン]':
    case '[핸드 캐논]':
    case '[Револьвер]':
      return '\ue103'; // 53304862

    case DestinyItemSubType.Shotgun:
    case '[Shotgun]':
    case '[Fusil à pompe]':
    case '[Fucile a pompa]':
    case '[Escopeta]':
    case '[Strzelba]':
    case '[Schrotflinte]':
    case '[ショットガン]':
    case '[산탄총]':
    case '[Дробовик]':
      return '\ue104'; // 258599004

    case DestinyItemSubType.SniperRifle:
    case '[Sniper Rifle]':
    case '[Fusil de précision]':
    case '[Fucile di precisione]':
    case '[Rifle de precisão]':
    case '[Karabin snajperski]':
    case '[Scharfschützengewehr]':
    case '[Fusil de francotirador]':
    case '[スナイパーライフル]':
    case '[저격총]':
    case '[Снайперская винтовка]':
      return '\ue105'; // 27869698

    case DestinyItemSubType.FusionRifle:
    case '[Fusion Rifle]':
    case '[Fusil à fusion]':
    case '[Fucile a fusione]':
    case '[Fuzil de fusão]':
    case '[Karabin fuzyjny]':
    case '[Fusionsgewehr]':
    case '[Fusil de fusión]':
    case '[フュージョンライフル]':
    case '[융합 소총]':
      return '\ue106'; // 579143207

    case DestinyItemSubType.SubmachineGun:
    case '[SMG]':
    case '[Pistolet-mitrailleur]':
    case '[Mitraglietta]':
    case '[Submetralhadora]':
    case '[Pistolet maszynowy]':
    case '[MP]':
    case '[Subfusil]':
    case '[サブマシンガン]':
    case '[기관단총]':
    case '[ПП]':
      return '\ue107'; // 218704521

    case DestinyItemSubType.RocketLauncher:
    case '[Rocket Launcher]':
    case '[Lance-roquettes]':
    case '[Lanciarazzi]':
    case '[Lança-foguetes]':
    case '[Wyrzutnia rakiet]':
    case '[Raketenwerfer]':
    case '[Lanzacohetes]':
    case '[ロケットランチャー]':
    case '[로켓 발사기]':
    case '[Ракетная установка]':
      return '\ue108'; // 238063032

    case DestinyItemSubType.Sidearm:
    case '[Sidearm]':
    case '[Pistolet]':
    case '[Arma da supporto]':
    case '[Pistola]':
    case '[Broń boczna]':
    case '[Pistole]':
    case '[ピストル]':
    case '[보조 무기]':
    case '[Пистолет]':
      return '\ue109'; // 299893109

    case '[Melee]':
    case '[Mêlée]':
    case '[Corpo a corpo]':
    case '[Walka wręcz]':
    case '[Nahkampf]':
    case '[Cuerpo a cuerpo]':
    case '[近接]':
    case '[근접]':
    case '[Ближний бой]':
      return '\ue110'; // 840587511

    case '[Grenade]':
    case '[Granata]':
    case '[Granat]':
    case '[Granate]':
    case '[グレネード]':
    case '[수류탄]':
    case '[Граната]':
      return '\ue111'; // 1900290540

    case DestinyItemSubType.GrenadeLauncher:
    case '[Grenade Launcher]':
    case '[Lance-grenades]':
    case '[Lanciagranate]':
    case '[Lança-granadas]':
    case '[Granatnik]':
    case '[Granatenwerfer]':
    case '[Lanzagranadas]':
    case '[グレネードランチャー]':
    case '[유탄 발사기]':
    case '[Гранатомет]':
      return '\ue113'; // 2279689475

    case '[Warlock: Dawnblade Super]':
    case '[Arcanistes : Super de la Lame de l\'aube]':
    case '[Stregone: super Lama dell\'alba]':
    case '[Arcano: Super de Esgrimista Áureo]':
    case '[Czarownik: superzdolność Ostrza Świtu]':
    case '[Warlock: Dämmerklinge-Super]':
    case '[Hechicero: Súper de Empuñasoles]':
    case '[ウォーロック: ドーンブレードスーパースキル]':
    case '[워록: 새벽칼날 궁극기]':
    case '[Варлок: Клинок зари - Суперспособность]':
      return '\ue119'; // 1363382181

    case '[Warlock: Voidwalker Super]':
    case '[Arcanistes : Super de la Voie abyssale]':
    case '[Stregone: super Camminatore del vuoto]':
    case '[Arcano: Super de Andarilho do Vácuo]':
    case '[Czarownik: superzdolność Kroczącego przez Próżnię]':
    case '[Warlock: Leere-Läufer-Super]':
    case '[Hechicero: Súper de Caminante del vacío ]':
    case '[ウォーロック: ボイドウォーカースーパースキル]':
    case '[워록: 공허방랑자 궁극기]':
    case '[Hechicero: Súper de Caminante del vacío]':
    case '[Варлок: Странник пустоты - Суперспособность]':
      return '\ue120'; // 1733112051

    case '[Hunter: Arcstrider Super]':
    case '[Chasseurs : Super de la Grâce survoltée]':
    case '[Cacciatore: super Fulminatore]':
    case '[Caçador: Super de Acrobata Voltaico]':
    case '[Łowca: superzdolność Gromodzierżcy]':
    case '[Jäger: Arkusakrobat-Super]':
    case '[Cazador: Súper de Jinete del arco]':
    case '[ハンター: アークストライダースーパースキル]':
    case '[헌터: 전기질주자 궁극기]':
    case '[Охотник: Громобежец - Суперспособность]':
      return '\ue121'; // 269520342

    case '[Titan: Striker Super]':
    case '[Titans : Super de l\'Assaillant]':
    case '[Titano: super Assaltatore]':
    case '[Titã: Super de Combatente]':
    case '[Tytan: superzdolność Szturmowca]':
    case '[Titan: Stürmer-Super]':
    case '[Titán: Súper de Asaltante]':
    case '[タイタン: ストライカースーパースキル]':
    case '[타이탄: 돌격자 궁극기]':
    case '[Титан: Воитель - Суперспособность]':
      return '\ue122'; // 2975056954

    case '[Hunter: Gunslinger Super]':
    case '[Chasseurs : Super du Pistolero]':
    case '[Cacciatore: super Pistolero]':
    case '[Caçador: Super de Pistoleiro]':
    case '[Łowca: superzdolność Strzelca]':
    case '[Jäger: Revolverheld-Super]':
    case '[Cazador: Súper de Pistolero]':
    case '[ハンター: ガンスリンガースーパースキル]':
    case '[헌터: 총잡이 궁극기]':
    case '[Охотник: Стрелок - Суперспособность]':
      return '\ue123'; // 1633845729

    case '[Titan: Sunbreaker Super]':
    case '[Titans : Super du Brise-soleil]':
    case '[Titano: super Spezzasole]':
    case '[Titã: Super de Demolidor Solar]':
    case '[Tytan: superzdolność Słonecznego Pogromcy]':
    case '[Titan: Sonnenbezwinger-Super]':
    case '[Titán: Súper de Quiebrasoles]':
    case '[タイタン: サンブレーカースーパースキル]':
    case '[타이탄: 태양파괴자 궁극기]':
    case '[Титан: Солнцелом - Суперспособность]':
      return '\ue126'; // 2905697046

    case '[Hunter: Nightstalker Super]':
    case '[Chasseurs : Super de l\'adepte de la Ronde de nuit]':
    case '[Cacciatore: super Predatore della notte]':
    case '[Caçador: Super de Predador Noturno]':
    case '[Łowca: superzdolność Tropiciela]':
    case '[Jäger: Nachtpirscher-Super]':
    case '[Cazador: Súper de Acechador nocturno]':
    case '[ハンター: ナイトクローラースーパースキル]':
    case '[헌터: 밤추적자 궁극기]':
    case '[Охотник: Ночной охотник - Суперспособность]':
      return '\ue127'; // 2904388000

    case '[Titan: Sentinel Super]':
    case '[Titans : Super de la Sentinelle]':
    case '[Titano: super Sentinella]':
    case '[Titã: Super de Sentinela]':
    case '[Tytan: superzdolność Wartownika]':
    case '[Titan: Sentinel-Super]':
    case '[Titán: Súper de Centinela]':
    case '[タイタン: センティネルスーパースキル]':
    case '[타이탄: 파수병 궁극기]':
    case '[Титан: Покровитель - Суперспособность]':
      return '\ue128'; // 1043633269

    case DestinyItemSubType.TraceRifle:
    case '[Trace Rifle]':
    case '[Fusil à rayon]':
    case '[Fucile laser]':
    case '[Fuzil de laser]':
    case '[Karabin wiązkowy]':
    case '[Spurgewehr]':
    case '[Fusil rastreador]':
    case '[トレースライフル]':
    case '[추적 소총]':
    case '[Fusil de rastreo]':
    case '[Лучевая винтовка]':

      return '\ue138'; // 1375652735

    case '[Solar]':
    case '[Solaire]':
    case '[Solare]':
    case '[Słoneczne]':
    case '[ソーラー]':
    case '[태양]':
    case '[Солнце]':
      return '\ue140'; // 245304809

    case '[Cryo-électrique]':
    case '[Elektryczne]':
    case '[Arkus]':
    case '[アーク]':
    case '[전기]':
    case '[Молния]':
      return '\ue141'; // 245304809

    case '[Headshot]':
    case '[Tirs de précision]':
    case '[Colpo alla testa]':
    case '[Tiro Preciso]':
    case '[Strzał w głowę]':
    case '[Helmtreffer ]':
    case '[Disparo a la cabeza]':
    case '[ヘッドショット]':
    case '[헤드샷]':
    case '[Tiro a la cabeza]':
    case '[Убийство прицельным выстрелом в голову]':
      return '\ue142'; // 27869698

    case '[Void]':
    case '[Abyssal]':
    case '[Vuoto]':
    case '[Vácuo]':
    case '[Próżniowe]':
    case '[Leere]':
    case '[Vacío]':
    case '[ボイド]':
    case '[공허]':
    case '[Пустота]':
      return '\ue144'; // 245304809

    case DestinyItemSubType.FusionRifleLine:
    case '[Linear Fusion Rifle]':
    case '[Fusil à fusion linéaire]':
    case '[Fucile a fusione lineare]':
    case '[Fuzil de Fusão Linear]':
    case '[Liniowy karabin fuzyjny]':
    case '[Linear-Fusionsgewehr]':
    case '[Fusil de fusión lineal]':
    case '[リニア・フュージョンライフル]':
    case '[선형 융합 소총]':
    case '[Линейная плазменная винтовка]':
      return '\ue152'; // 1612898592

    case DestinyItemSubType.Sword:
    case '[Sword]':
    case '[Épée]':
    case '[Spada]':
    case '[Espada]':
    case '[Miecz]':
    case '[Schwert]':
    case '[剣]':
    case '[검]':
    case '[Меч]':
      return '\ue153'; // 2526872641

    case DestinyItemSubType.Machinegun:
    case '[Machine Gun]':
    case '[Mitrailleuse]':
    case '[Mitragliatrice]':
    case '[Metralhadora]':
    case '[Karabin maszynowy]':
    case '[Maschinengewehr]':
    case '[Ametralladora]':
    case '[マシンガン]':
    case '[기관총]':
    case '[Пулемет]':
      return '\ue154'; // 1452824294

    case DestinyItemSubType.Glaive:
    case '[Glaive]':
      return ''; // 1452824294

    default:
      return value;
  }
};

export interface CleanupConfig {
  ownerId: string;
  owner: string;
  ownerCode: number;
  toKeep: DestinyRecipesItem[];
  toRemove: DestinyRecipesItem[];
  date: number;
  cleaner: string;
  cleanerCode: number;
}

export interface RecipesCraftableState {
  craftable: boolean;
  unlocked?: boolean;
  errorMessages?: string[];
}

export enum AnchorPositionOptions {
  Bottom = 'bottom',
  Top = 'top',
}

export enum FloatingPositionOptions {
  Left = 'left',
  Right = 'right',
}

export type FloatingOptions = {
  position: FloatingPositionOptions;
  reduceMobileSize?: boolean;
};

export enum FormatOptions {
  Anchor = 'anchor',
  AnchorV2 = 'anchor-v2',
  Article = 'article',
  Display = 'display',
  Floating = 'floating',
  Interstitial = 'interstitial',
  Rail = 'rail',
  Rewarded = 'rewarded',
  StickyStack = 'sticky-stack',
  Video = 'video',
  VideoNC = 'video-nc',
  Wallpaper = 'wallpaper',
}

export type InterstitialOptions = {
  triggers: Partial<Record<'unhideWindow', boolean>>
};

export enum OutstreamOptions {
  Auto = 'auto',
  Never = 'never',
}

export enum FloatOptions {
  After = 'after',
  Always = 'always',
  Auto = 'auto',
  Never = 'never',
}

export enum MobileOptions {
  Compact = 'compact',
  Full = 'full',
}

export type VideoOptions = {
  float: FloatOptions;
  hidePlaylist: boolean;
  initialDelay?: number;
  interval?: number;
  mobile: MobileOptions;
  persistMinimizeTime?: number;
};

export enum ReportPosition {
  BottomCenter = 'bottom-center',
  BottomLeft = 'bottom-left',
  BottomLeftSide = 'bottom-left-side',
  BottomRight = 'bottom-right',
  BottomRightSide = 'bottom-right-side',
  TopCenter = 'top-center',
  TopLeft = 'top-left',
  TopLeftSide = 'top-left-side',
  TopRight = 'top-right',
  TopRightSide = 'top-right-side',
}

export type ReportOptions = {
  enabled?: boolean;
  icon?: boolean;
  position?: ReportPosition;
  wording?: string;
};

export type RewardedOptions = {
  accept?: string;
  decline?: string;
  prompt: string;
  rewardRejected: () => void;
  wait?: string;
};

export type PlacementTargetingValue = string;

export type PlacementTargeting = {
  [key: string]: PlacementTargetingValue | PlacementTargetingValue[];
};



export interface NitroAdOptions {
  format: FormatOptions;
  acceptable?: boolean;
  anchor?: AnchorPositionOptions;
  anchorBgColor?: string;
  anchorClose?: boolean;
  anchorPersistClose?: boolean;
  className?: string;
  contentRating?: string;
  delayLoading?: boolean;
  demo?: boolean;
  floating?: FloatingOptions;
  frequencyCap?: number;
  geoAllow?: string[];
  geoDeny?: string[];
  group?: string;
  interstitial?: InterstitialOptions;
  keywords?: string;
  mediaQuery?: string;
  outstream?: OutstreamOptions;
  pageInterval?: number;
  rail?: string;
  railCloseColor?: string;
  railCollisionWhitelist?: string[];
  railDistance?: number;
  railOffsetBottom?: number;
  railOffsetTop?: number;
  railSpacing?: number;
  railStack?: boolean;
  refreshDisabled?: string[];
  refreshLimit?: number;
  refreshTime?: number;
  refreshVisibleOnly?: boolean;
  renderVisibleOnly?: boolean;
  report?: ReportOptions;
  rewarded?: RewardedOptions;
  sizes?: string[][];
  skipBidders?: string[];
  stickyStackLimit?: number;
  stickyStackOffset?: number;
  stickyStackResizable?: boolean;
  stickyStackSpace?: number;
  targeting?: PlacementTargeting;
  title?: string;
  video?: VideoOptions;
  visibleMargin?: number;
}

export interface NitroAd {
  id: string;
  options: NitroAdOptions;
  onNavigate(): void;
  renderContainers(): void;
}

declare class NitroAds {
  createAd: (divId: string, options: NitroAdOptions) => NitroAd;
}

declare global {
  interface Window { nitroAds: NitroAds; }
}
