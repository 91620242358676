import { Component, OnInit, ViewChild } from '@angular/core';
import { CoreService } from '../../services/core.service';
import { DataService } from '../../services/data.service';
import { LoadingComponent } from '../../types';
import { ActivationEnd, Router } from '@angular/router';
import { audit, filter } from 'rxjs/operators';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { DimSyncService } from '../../services/dim-sync.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '@Services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translate3d(-100%,0,0)'
      })),
      state('out', style({
        transform: 'translate3d(0, 0, 0)'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ]),
  ]
})
export class HeaderComponent implements OnInit {

  languages = [
    { value: 'en', text: 'English' },
    { value: 'de', text: 'Deutsch' },
    { value: 'es', text: 'Español' },
    { value: 'es-mx', text: 'Español (México)' },
    { value: 'fr', text: 'Français' },
    { value: 'it', text: 'Italiano' },
    { value: 'ja', text: '日本語' },
    { value: 'ko', text: '한국어' },
    { value: 'pl', text: 'Polski' },
    { value: 'pt-br', text: 'Português' },
    { value: 'ru', text: 'Русский' },
    { value: 'nb', text: 'Norwegian Bokmål' },
    { value: 'sr-cs', text: 'Српски' },
    { value: 'zh-chs', text: '简体中文' },
    { value: 'zh-cht', text: '繁體中文' },
    { value: 'ar', text: 'Arabic' },
  ];
  tools = [
    { name: 'TOOLS.CHECKLIST.SHORT_NAME', path: ['checklist'], icon: ['fas', 'tasks'], },
    { name: 'TOOLS.PROGRESS.SHORT_NAME', path: ['power'], icon: ['fas', 'align-left'], },
    { name: 'TOOLS.LOOT_COMPANION.SHORT_NAME', path: ['loot'], icon: ['fab', 'watchman-monitoring'], },
    { name: 'TOOLS.VAULT_CLEANER.SHORT_NAME', path: ['vault'], icon: ['fab', 'buromobelexperte'], },
/*    { name: 'TOOLS.APPRAISER.SHORT_NAME', path: ['appraiser'], icon: ['fas', 'microscope'], },*/
  ];
  currentLang;
  currentTool: any;
  sideMenuState: 'out' | 'in' = 'in';
  opaque = false;

  get loadingDimSync() {
    return this.dimSync.loading;
  }

  get dimSyncAvailable() {
    return !!this.dimSync.profile;
  }

  constructor(
    public auth: AuthService,
    private coreService: CoreService,
    private dataService: DataService,
    private router: Router,
    private dimSync: DimSyncService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.dataService.language;
    this.currentTool = this.tools.find((e) =>  this.router.url.includes(e.path.join('/'))) || null;
    this.router.events
      .pipe(filter((event) => event instanceof ActivationEnd))
      .subscribe((event: ActivationEnd) => {
        console.log(this.router.url);
        this.currentTool = this.tools.find((e) => this.router.url.includes(e.path.join('/'))) || null;
        this.opaque = this.currentTool === this.tools[4];
      });
  }

  changeLanguage(lang) {
    if (lang !== this.currentLang) {
      this.currentLang = lang;
      this.dataService.setLanguage(this.currentLang);
      this.coreService.loadComponents([LoadingComponent.MANIFEST]);
    }
  }

  navigateTo(route: string[]) {
    this.router.navigate(route);
  }

  refreshDimSync() {
    if (!this.loadingDimSync) {
      this.dimSync.loadDimSyncProfile();
    }
  }
}
