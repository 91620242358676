<script src="../../services/dim-sync.service.ts"></script>
<div class="header" [class.opaque]="opaque">
  <div class="title">
    <a [routerLink]="['']" class="header-link">
      <img class="logo" alt="Destiny Recipes logo" src="./assets/logo/logo.svg"> {{'TITLE' | translate}}
    </a>
    <div class="nav-links">
      <a *ngFor="let tool of tools" [routerLink]="tool.path" [routerLinkActive]="'active'">{{tool.name | translate}}</a>
    </div>
  </div>
  <div class="actions">
    <a class="dim-sync" [ngClass]="{'loading': loadingDimSync}" (click)="refreshDimSync()" *ngIf="auth.authenticated">
      <fa-icon class="not-available" [title]="'HEADER.LOAD_ERROR' | translate" *ngIf="!dimSyncAvailable" [icon]="['fas', 'exclamation-circle']"></fa-icon>
      <fa-icon [icon]="['fas', 'spinner']" spin="true" *ngIf="loadingDimSync"></fa-icon>
      <img *ngIf="!loadingDimSync" src="data:image/svg+xml,%3csvg viewBox='0 0 921 921' xmlns='http://www.w3.org/2000/svg'%3e%3cg fill='%23EE6D0D'%3e%3cpath d='m368.8 462.1 91.7 91.8 91.8-91.8-91.8-91.7z'/%3e%3cpath d='m460.5 3.3-91.7 91.8 367 367-275.3 275.3-275.3-275.3 183.6-183.5-91.8-91.8L1.7 462.1l458.8 458.8 458.8-458.8z'/%3e%3c/g%3e%3c/svg%3e" alt="DIM Logo" height="18" width="18"> {{'HEADER.SYNC' | translate}}
    </a>
   <app-dropdown placeholder="Select language" [optionList]="languages" [icon]="['fas', 'globe']" (selected)="changeLanguage($event)" [currentValue]="currentLang"></app-dropdown>
    <a class="discord" title="Join us on discord" href="https://discord.gg/cX8R6wPRB8" target="_blank"><fa-icon [icon]="['fab', 'discord']" size="lg"></fa-icon></a>
    <div class="clarity-partner">
      <img src="assets/clarity.png" alt="Clarity Logo">
      <div class="dropdown-clarity">
        <div class="clarity-title"><img src="assets/clarity.png" alt="Clarity Logo"> Destiny Recipes is enhanced by Clarity</div>
        <p>Community insights for weapons traits are kindly provided by Clarity</p>
        <p>When you see the Clarity logo, it means the perk description has been enriched with detailed informations deduced by the community.</p>
        <div class="row">
          <a href="https://d2clarity.com" target="_blank">Visit Clarity</a>
          <a href="https://ko-fi.com/d2clarity" target="_blank">Donate to Clarity</a>
        </div>
      </div>
    </div>
    <app-bungie-card small="true"></app-bungie-card>
  </div>
  <div class="mobile-header">
    <fa-icon [icon]="['fas', 'bars']" (click)="sideMenuState = 'out'"></fa-icon>
    <div class="right-side">
      <a class="discord" title="Join us on discord" href="https://discord.gg/cX8R6wPRB8" target="_blank"><fa-icon [icon]="['fab', 'discord']" size="lg"></fa-icon></a>
    </div>
  </div>
</div>
<section id="side-menu" [@slideInOut]="sideMenuState">
  <h4 [routerLink]="['/']" (click)="sideMenuState = 'in'"><img class="logo" alt="Destiny Recipes logo" src="./assets/logo/logo.svg"> {{'TITLE' | translate}}</h4>
  <app-bungie-card></app-bungie-card>
  <div class="side-nav-links">
    <a *ngFor="let tool of tools" [routerLink]="tool.path" [routerLinkActive]="'active'" (click)="sideMenuState = 'in'">
      <fa-icon [icon]="tool.icon"></fa-icon>
      <span>{{tool.name | translate}}</span>
    </a>
  </div>
</section>

<div class="side-menu-backdrop" (click)="sideMenuState = 'in'" *ngIf="sideMenuState === 'out'"></div>

